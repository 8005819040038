$datepicker__background-color: #f0f0f0 !default;
$datepicker__border-color: #aeaeae !default;
$datepicker__highlighted-color: #3dcc4a !default;
$datepicker__muted-color: #ccc !default;
$datepicker__selected-color: #216ba5 !default;
$datepicker__text-color: #000 !default;
$datepicker__header-color: #000 !default;
$datepicker__navigation-disabled-color: lighten($datepicker__muted-color, 10%)
  !default;

$datepicker__border-radius: 50% !important;

.custom-calendar-small .react-datepicker {
    width: 215px;
    min-height: 330px;
    font-family: Poppins;
    background-color: #fff;
    color: #000;
    border-radius: 0.3rem;
    display: inline-block;
    position: relative;
    border: 0;
}

.custom-calendar-small .react-datepicker__header{
    display: flex;
    flex-direction: column;
    background-color: white;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    position: relative;
    border-bottom: 0;
}

.custom-calendar-small .react-datepicker__month-container{
    width: 100%;
    float: left;
}

.custom-calendar-small .react-datepicker__month{
    margin: 0;
    margin-top: 5px;
}

.custom-calendar-small .react-datepicker__day-names{
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.custom-calendar-small .react-datepicker__week{
    background-color: white;
    display: flex;
    width: 100%;
}

.custom-calendar-small .react-datepicker__day{
    display: inline-block;
    color: #495057;
    height: 30px;
    background-color: white;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    font-family: Poppins;
    outline: none;
}

.custom-calendar-small .react-datepicker__day-name{
    color: #788195;
    display: inline-block;
    height: 40px;
    width: 100%;
    background-color: #f8f9fa;
    text-align: center;
    padding-top: 10px;
    font-size: 13px;
    font-weight: 500;
    font-family: Poppins;
    margin: 0;
    line-height: unset;
}

.custom-calendar-small .react-datepicker__month--selected{
    border-radius: 50%;
    background-color: #216ba5;
    color: #fff;
}

.custom-calendar-small .react-datepicker__day:hover{
    border-radius: 50%;
    background-color:white;
}

.custom-calendar-small .react-datepicker__day--selected{
    background-color: #005eb8;
    border-radius: 50%;
    max-width: 25px;
    max-height: 27px;
    text-align: center;
    font-size: 12px;
    color: #fff;
}

.custom-calendar-small .react-datepicker__day--selected:hover{
    background-color: #005eb8;
    border-radius: 50%;
}

.custom-calendar-small .font-color-of-exclude-day{
    color: #bbbdc3;
}

.chevron-size-small{
  font-size: 20px;
}

.chevron-button-small{
  padding-top: 5px;
  width: 35px;
  max-height: 30px;
  cursor: pointer;
}

.custom-calendar-small .date-label{
    color: #495057;
}

.custom-calendar-small .react-datepicker__day,
.custom-calendar-small .react-datepicker__month-text,
.custom-calendar-small .react-datepicker__quarter-text,
.custom-calendar-small .react-datepicker__year-text {
  cursor: pointer;

  &--today {
    font-weight: bold;
  }

  &--highlighted {
    border-radius: $datepicker__border-radius;
    background-color: $datepicker__highlighted-color;
    color: #fff;
  }
 
  &--range-start,
  &--range-end {
    background-color: #005eb8!important;
    border-radius: 50%;
    margin-top: 9px;
    padding-top: 3px;
    max-width: 30px;
    max-height: 30px;
    margin-right: 10px;
    margin-left: 7px;
    text-align: center;
    font-size: 14px;
    color: #fff!important;
  }

  &--in-selecting-range,
  &--in-range {
    background-color: #f2f7fe;
    margin-top: 9px;
    padding-top: 3px;
    max-height: 30px;
    min-width: 30px;
    text-align: center;
    font-size: 14px;
    color: #000;
  }

  &--in-selecting-range:not(&--in-range) {
    background-color: rgba( #f2f7fe, 0.5);
  }

  &--in-range:not(&--in-selecting-range) {
    .react-datepicker__month--selecting-range & {
      background-color: $datepicker__background-color;
      color: $datepicker__text-color;
    }
  }

  &--selecting-range-start,
  &--selecting-range-end{
    background-color: #005eb8!important;
    border-radius: 50%;
    margin-top: 9px;
    padding-top: 3px;
    max-width: 30px;
    max-height: 30px;
    margin-right: 10px;
    margin-left: 7px;
    text-align: center;
    font-size: 14px;
    color: #fff!important;
  }
  &--disabled {
    cursor: default;
    color: $datepicker__muted-color;
  }
}
