.attached-photo-card{

    min-height: 588px;
    height: 588px;
    border-radius: 4px;

    .natural-image{
        max-width: 100%;
        max-height: 100%;
        width: fit-content;
        height: fit-content;
        object-fit: fill;
    }
    
    &--placeholder{

        &__image{
            width: 300px;
            height: 258px;
            object-fit: contain;
            margin-top: 88px;
            margin-bottom: 30px;
        }

        &__label{
            font-size: 16px;
            font-weight: normal;
            color: $gunmetal;
        }

        &__comments-image{
            width: 150px;
            margin-top: 37px;
            height: 156px;  
            object-fit: contain;
            margin-bottom: 23px;
        }
    }
}

.history-comments-card{
    padding: 20px;
    padding-bottom: 0;
    height: 326px;
    max-height: 326px;
    border-radius: 4px;

    &--list{
        max-height: 210px;
        margin-bottom: 25px;
    }

    .comments-section{
        overflow-y: auto;
    
        &--section{
            margin-top: 35px;
            margin-bottom: 45px;
    
            &__label{
                font-size: 16px;
                font-weight: normal;
                color: $gunmetal;
            }
        
            &__comments-image{
                width: 150px;
                height: 156px;  
                object-fit: contain;
                margin-bottom: 20px;
            }
        }
    }
}

.history-appointment-details{
    padding: 20px 20px 24px;
}

.document-field {
    &-label {
        &:disabled {
            color: $gray-600;
        }
    }

    &-input {
        &:disabled {
            background-color: #e9ecef;
            opacity: 1;
        }
    }
}