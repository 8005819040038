.online-clinicians-table{
    max-width: 215px;
}

.no-clinicians-label{
    font-size: 14px;
    font-weight: normal;
}

.online-clinicians-placeholder{
    height: 70%;
    width: 70%;
}