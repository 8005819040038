.session-table {
  max-height: 600px;
  min-height: 600px;
}

.session-table-placeholder {
  min-height: 535px;
}

.create-session-timepicker {
  width: 185px;
}

.custom-design-scroll ::-webkit-scrollbar {
  width: 3px;
}

.custom-design-scroll ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.custom-design-scroll ::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #bbbdc3;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.custom-design-scroll ::-webkit-scrollbar-thumb:window-inactive {
  background: #bbbdc3;
}

.session-table-card {
  height: 740px;
  width: 420px;
  max-width: 420px;
  min-width: 420px;

  &__action-label {
    font-size: 13px;
    font-weight: 600;
    color: $gunmetal;
    padding-left: 24px;
  }

  .thead-light {
    th {
      padding-left: 4px;
      padding-right: 4px;
    }

    th:nth-child(1) {
      width: 50px;
      max-width: 50px;
    }

    th:nth-child(2) {
      width: 140px;
    }
  }


}

.session-table-card .table-text {
  color: #495057;
}

.session-table-card .custom-dropdown-styles {
  box-shadow: 0 10px 20px 0 rgba(18, 38, 63, 0.03);
}

.custom-table-hover:hover {
  background-color: rgba($color: #50a5f1, $alpha: 0.09);
}

.chosen-patient .dropdown-icon {
  width: 24px;
  height: 24px;
}

.session-table .background-secondary {
  background-color: #bbbdc3;
}

.session-table .background-danger {
  background-color: #d70000;
}

.session-table .background-success {
  background-color: #34c38f;
}

.session-table .background-blocked {
  background-color: #788195;
}

.session-table .background-primary {
  background-color: #50a5f1;
}

.session-table .background-warning {
  background-color: #f1b44c;
}

.session-table .background-info {
  background-color: #50a5f1;
}

.page-content .background-warning {
  background-color: #fcc000;
  color: white;
}

.page-content .background-dark-primary {
  background-color: #005eb8;
  color: white;
}

.page-content .background-transparent {
  background-color: transparent;
}

.page-content .background-violet {
  background-color: #7600fc;
}

.session-placeholder-big {
  font-size: 16px;
  font-weight: normal;
  color: #495057;
  margin-top: 35px;
}

.consultation-modal-form-label {
  font-size: 22px;
  font-weight: normal;
  color: #495057;
}

.consultation-modal-form {
  padding: 40px 40px 39px 36px;
}

.table-responsive::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.table-responsive ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.table-responsive::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #bbbdc3;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.table-responsive::-webkit-scrollbar-thumb:window-inactive {
  background: #bbbdc3;
}

.comments-admin-name {
  font-size: 14px;
  font-weight: 500;
  color: #495057;
}

.comments-text {
  font-size: 13px;
  font-weight: normal;
  color: #495057;
}

.comments-modal {
  max-height: 500px;
  overflow-y: auto;
}

.patient-info {
  font-size: 16px;
  font-weight: 500;
  color: #495057;
}


.patient-label {
  font-size: 13px;
  font-weight: normal;
  color: #788195;
}

.custom-dropdown-styles .disabled-text {
  color: #bbbdc3;
}

.session-table .appointment-row {
  max-height: 20px;
  height: 20px;
  padding: 0;

  td:nth-child(1) {
    width: 50px;
    max-width: 50px;
  }

  td:nth-child(2) {
    width: 140px;
    max-width: 140px;
  }
}

.session-table .appointment-row-hover:hover {
  background-color: rgba($color: #50a5f1, $alpha: 0.09);
}

.session-table-width {
  min-width: 430px;
}

.picked-slot-bg {
  background-color: rgba($color: $dodger-blue, $alpha: 0.09);
}

.simple-appointment-checkbox {
  z-index: 0;
  margin-top: 25px;
  margin-bottom: 25px;

  &__label {
    font-size: 13px;
    color: $gunmetal;
    font-weight: 500;
  }
}

.main-page-calendar-margin {
  margin-right: 13px;
  margin-left: 13px;
}

.session-placeholder {

  height: 740px;

  &__image {
    width: 210px;
    height: 134px;
    object-fit: contain;
  }

  &__text {
    margin-top: 20px;
    font-size: 16px;
    font-weight: normal;
    color: $gunmetal;
  }
}

.page-content .create-session-button {
  background-color: #005EB8;
  color: white;
}

.page-content .create-session-button:hover {
  background-color: #004D98;
  color: white;
}
