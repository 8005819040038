.public-patient-change-password,
.public-patient-resend-password,
.public-patient-expired-link,
.switch-to-mobile-device {
  font-family: 'Montserrat', sans-serif;
  background-color: #FFFFFF;
  height: 100vh;
}

.public-patient-change-password {
  &__heading {
    color: #1D2239;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    margin-bottom: 44px;
  }

  .change-password-image {
    position: relative;
    transform: translateX(-18px);
    margin-bottom: 33px;
  }

  .card-auth .eye-position {
    top: 0;
    right: 8px;
  }

  .form-group {
    margin: 35px 0 17px 0;
  }

  .form-group:last-of-type {
    margin-bottom: 45px;
  }

}

.public-patient-change-password .form-control,
.public-patient-resend-password .form-control {
  border: none;
  border-radius: unset;
  border-bottom: 1px solid rgba(119, 122, 136, 0.1);
  padding: 0;

  color: #777A88;
  font-size: 14px;
  font-weight: 500;
}

.public-patient-change-password .form-control.is-invalid,
.public-patient-resend-password .form-control.is-invalid {
  border: none;
  background: none;
  border-bottom: 1px solid #f46a6a;
}

.public-patient-change-password .form-control.is-invalid:focus,
.public-patient-resend-password .form-control.is-invalid:focus {
  box-shadow: none;
}


.public-patient-change-password .btn-primary,
.public-patient-expired-link .btn-primary,
.public-patient-resend-password .btn-primary {
  background-color: #50A5F1;
  border-radius: 22px;
  border-color: #50A5F1;
  padding-top: 12px;
  padding-bottom: 12px;

  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
}

.public-patient-change-password .btn-primary:focus,
.public-patient-expired-link .btn-primary:focus,
.public-patient-resend-password .btn-primary:focus {
  border-color: #50A5F1;
}

.public-patient-expired-link {
  &__content {
    width: 310px;
    font-size: 14px;
    font-weight: 500;
  }

  .btn-sign-in {
    padding: 24px;

    color: #1D2239;
    font-size: 12px;
    font-weight: 600;
  }


  .btn-sign-in:focus {
    box-shadow: none;
  }
}

.public-patient-resend-password {
  &__content {
    width: 296px;
  }

  .form-group {
    margin-bottom: 235px;

    label {
      margin-bottom: 0;

      color: #BBBDC3;
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
    }
  }

  .form-control {
    height: 32px;
  }
}

.switch-to-mobile-device {
  &__text {
    width: 254px;
    color: #1D2239;
    font-size: 14px;
    font-weight: 500;
  }
}


input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #FFFFFF inset !important;
  background-color: transparent !important;
}

