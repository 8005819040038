.append-input-duration-min{
    position: absolute;
    top: 35px;
    left: 195px;
    color: #495057;
}

.manage-session-caption{
    color: #495057;
}

.custom-z-index{
    z-index: 0;
}

.menu-list-styles{
    font-size: 13px;
    font-weight: 600;
    margin-left: 12px;
}