@import "../../assets/scss/theme.scss";

.exit-icon-size{
    font-size: 24px;
}

.log-out-header{
    font-family: Poppins;
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #495057;
}

.modal-button-styles{
    width: 187px;
    height: 36px;
    padding: 9px 84px 0 85px;
    border-radius: 4px;
}

.modal-text-styles{
    font-size: 16px;
}

.custom-sidebar-menu .active-sidebar-link{
    color: #ffffff;
    opacity: 1!important;
}

.custom-sidebar-menu .sidebar-link{
    color: #ffffff!important;
    opacity: 0.7;
}