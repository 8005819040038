.dropzone-file {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  border: 2px dashed $gray-400;
  padding: .625rem .125rem;
  border-radius: 0.25rem;
  cursor: pointer;

  div {
    outline: none;
  }

  .upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    .cloud {
      font-size: 2.3rem;
      transform: scale(1.24);
      margin-bottom: .5rem;
    }

    &__text {
      font-size: 13px;
      font-weight: 500;
      text-align: center;
      padding: 0 .625rem;
    }
  }

  .drag-active {
    border: 2px dashed $gray-400;
    border-radius: 6px;
  }
}

.uploaded-image-dropzone {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.clinician-dropzone {
  min-height: 125px;
}