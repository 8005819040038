.pdf-modal{
    max-width: 1020px;
    height: 1599px;
}

.modal-container{
    background-color: #f8f8fb;
}

.pdf-header{
    max-width: 877px;
    height: 21px;
    background-color: #fcc000;
}

.pdf-container{
    width: 877px;
    padding: 58px 63px 0 40px;
}

.letter-id{
    position: absolute;
    bottom: 24px;
    left: 40px;
    color: #ffffff;
}

.letter-adress{
    position: absolute;
    bottom: 24px;
    left: 403px;
    color: #ffffff;
}

.letter-adress .email-link{
    font-weight: 500;
    text-decoration: underline;
    color: #ffffff;
}

.input-label-pdf{
    font-size: 13px;
    font-weight: 500;
    color: #495057;
}

.pdf-label-colleague{
    margin-top: 36px;
    font-size: 16px;
    font-weight: normal;
    color: #495057;

    &__signature{
        margin-left: 415px;
        margin-top: 13px;
        width: 77px;
        height: 55px;
    }
}

.pdf-label-ask{
    font-size: 15px;
    font-weight: 600;
    color: #495057;
}

.pgf-button{
    width: 20%;
}

.drug-supply{
    color: #495057;
    font-size: 13px;
    font-weight: 600;
}

.prescription-card{

    &--section{
        margin-top: 20px;

        &__label{
            font-size: 13px;
            font-weight: 600;
            color: $gunmetal;
        }

        &__value{
            font-size: 13px;
            font-weight: normal;
            color: $gunmetal;
        }
    }

    &__bottom-section{
        margin-top: 37px;
    }
    
    .drug-inputs{

        margin-top: 3px;

        &__first{
            .form-control{
                border-bottom-left-radius: 0;
                border-top-left-radius: 8px;
                border-bottom-right-radius: 0;
                border-top-right-radius: 0;
            }
        }

        &__second{
            .form-control{
                border-radius: 0;
            }
        }

        &__third{
            .form-control{
                border-bottom-left-radius: 0;
                border-top-left-radius: 0;
                border-bottom-right-radius: 0;
                border-top-right-radius: 8px;
            }
        }

        &__label{
            border: 1px solid #ced4da;
            border-bottom-left-radius: 8px;
            border-top-left-radius: 0;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
        }

        &__fouth{
            border-radius: 0;
        }

        &__fifth{
            .form-control{
                border-bottom-left-radius: 0;
                border-top-left-radius: 0;
                border-bottom-right-radius: 8px;
                border-top-right-radius: 0;
            }
        }
    }
}