.noti-icon .badge{
    left :23px;
}
.mt-2
{
 margin-top: 20px!important;
}

.dataTables_filter, .dataTables_paginate
{
    float: right;
}

.rdw-editor-main
{
    border: 1px solid #eeeef5;
    height: 239px;
}
.dz-message
{
     text-align: center;
    padding: 100px;
}
.fcYNFc
{
        background-color: #ffffff!important;
}
.task-box 
{
    border : 1px solid #e1e1e6;
}
.react-datepicker-wrapper
{
    width: 100%!important;
}

.ReactModal__Overlay
{
  z-index: 1001!important;
}

.fc-event .fc-content{
    padding: 5px;
    color: #FFF;
}
.chat-conversation .right .conversation-list{
margin-right: 15px;
}

.external-event{
    &:hover{
        cursor: pointer;
    }
}

.rangeslider-horizontal .rangeslider__fill
{
    background-color: #7cb342!important;
}

.modal-size .changePasswordSuccessLogo{
    height: 110px;
    padding: 0 0 1.6px;
    object-fit: contain;
}

.modal-size .success-password-modal{
    width: 100%;
    height: 100%;
    padding: 58px 28px 24px;
    border-radius: 8px;
    box-shadow: 0 10px 20px 0 rgba(18, 38, 63, 0.03);
    background-color: rgba(18, 38, 63, 0.03);
}

.modal-size{
    min-width: 451px;
    height: 344px; 
}

.patient-modal{
    width: 327px;
    height: 334px;
    border-radius: 4px;
    margin: auto;

    font-family: 'Montserrat', sans-serif;

    .modal-body {
        padding: 64px 54px 34px 54px;
        border-bottom: 1px solid rgba(119, 122, 136, 0.1);
    }

    .card {
        margin-bottom: 0;
    }

    .btn {
        padding: 14px;

        color: #005EB8;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
    }
}

.cursor-pointer{
    cursor: pointer;
}

.success-alert-message{
    color: #1c654a;
}

.custom-text-secondary{
    color: #788195;
}

.custom-avatar-size{
    min-width: 62px;
    min-height: 62px;
    width: 62px;
    height: 62px;
}

.big-avatar-custom{
    width: 225px;
    height: 225px;
}

.show-more-search{
    color: #005eb8;
    font-size: 13px;
    font-weight: 600;
}

.big-modal{
    min-width: 90vw;
}

.cursor-default{
    cursor: default!important;
}


.file-row {
    &-label {
        color: $gray-600;
    }

    &-icon {
        min-width: 68px;
        min-height: 68px;
        width: 68px;
        height: 68px;

        &-bg {
            background-color: #005EB833;
        }
    }
}

.custom-container-scrollbar {
    @media (min-width: 1024px) {
        width: calc(100% + 10px);
        margin-right: 6px;
        padding-right: 4px;

        &::-webkit-scrollbar {
            width: 6px;
            height: 6px;
        }
        &::-webkit-scrollbar-track {
            border-radius: 10px;
            background: rgba(10,154,172,0.0);
        }
        &::-webkit-scrollbar-thumb{
            padding-top: 44px;
            border-radius: 10px;
            background: rgba(0,94,184,0.2);
        }
        &::-webkit-scrollbar-thumb:hover{
            background: rgba(0,94,184,0.4);
        }
        &::-webkit-scrollbar-thumb:active{
            background: rgba(0,94,184,.9);
        }
    }
}

.custom-search-input {
    img {
        z-index: 1;
    }
}
