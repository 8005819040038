.nav-tabs-custom .tab-element{
    min-width: 230px;
}

.search-option{
    background-color: #ffffff;
    border-radius: 50px;
    border: solid 1px #ced4da;
}

.search-option {
    .form-control {
        height: 38px;
        padding-left: 40px;
        padding-right: 20px;
        background-color: #ffffff;
        box-shadow: none;
        border-radius: 50px;
        border: solid 1px #ced4da;
    }

    span {
        position: absolute;
        z-index: 10;
        font-size: 16px;
        line-height: 38px;
        left: 13px;
        top: 0;
        color: $gray-600;
    }
}

.filter-option{
    border-radius: 30px;
    width: 148px;
    height: 36px;
}

.filter-icon{
    position: absolute;
    top: 9px;
    left: 20px;
}

.table-caption{
    max-width: 100px;
    font-weight: 600;
}

.patient-avatar-cell{
    width: 35px;
    height: 35px;
    border-radius: 50%;
    object-fit: fill;
}

.patient-table .custom-table-hover {
    border-bottom: 1px solid #eff2f7;
    max-height: 65px;
}

.patient-table .custom-cell-hover {
    max-height: 65px;
}

.patient-status-badge{
    width: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.patients-placeholder-label{
    font-size: 16px;
    font-weight: normal;
    color: #495057;
    padding-top: 25px;
}

.patients-table{
    min-height: 750px;
}

.patients-placeholder{
    margin-top: 210px;
}

.history-placeholder{
    margin-top: 150px;
}

.pagination .shevron{
    font-size: 25px;
}

.details-caption{
    font-size: 15px;
    font-weight: 600;
}

.patient-document{
    box-shadow: 0 12px 24px 0 rgba(18, 38, 63, 0.03);
    outline: solid 1px #f6f6f6;
    padding: 20px;
    min-width: 340px;
}

.parent-document{
    outline: 0;
}

.status-label{
    font-size: 13px;
    font-weight: normal;
    color: #788195;
}

.patient-document .document-title{
    font-size: 15px;
    font-weight: 500;
    color: #495057;
}

.patient-document .color-primary{
    background-color: rgba(80, 165, 241, 0.18);
    color: #50a5f1;
}

.patient-document .color-success{
    background-color: rgba(52, 195, 143, 0.18);
    color: #34c38f;
}

.patient-document .color-danger{
    background-color: rgba(215, 0, 0, 0.18);
    color: #d70000;
}

.document-photo{
    border-radius: 4px;
    width: 155px;
    height: 110px;
    object-fit:contain;
}

.patient-document .compare-label{
    font-size: 13px;
    font-weight: 500;
    color: #495057;
}

.patient-document .status-button{
    width: 100px;
}

.avatar-placeholder{
    min-width: 35px;
    min-height: 35px;
}

.custom-shevron{
    font-size: 20px;
}

.search-placeholder{
    color: #788195;
    font-size: 13px;
    font-weight: normal;
}

.search-input{
    font-size: 13px;
    font-weight: normal;
    color: #495057;
    font-family: Poppins;
}

.search-options{
    max-width: 265px;
    font-size: 13px;
    font-weight: normal;
    color: #495057;
}

.search-options-big{
    font-size: 13px;
    font-weight: normal;
    color: #495057;
}

.global-search-options{
    margin-top: 12px;
    margin-bottom: 12px;
    font-size: 13px;
    font-weight: normal;
    color: #495057;
}

.global-search-options:hover{
    cursor: pointer;
    background-color: #DEEBFF;
}

.search-icon{
    position: absolute;
    margin-left: 10px;
}

.attached-photo{
    max-width: 223px;
    max-height: 144px;
    width: 100%;
    height: 100%;
}

.patients-table{
    min-height: 750px ;
}

.patients-table .table-caption-label{
    font-weight: 500;
}

.text-underline{
    text-decoration: underline;
    color: #495057;
}

.edit-patient-card{
    padding: 20px 20px 50px;
    border-radius: 4px;
}

.edit-patient-card .input-heigth{
    max-height: 37px;
    color: #495057;
    font-size: 13px;
    font-weight: normal;
}

.input-patient-year{
    width: 22%;
    margin-left: 20px;
    padding-top: 7px;
    color: #495057;
    font-size: 13px;
    font-weight: normal;
}

.edit-page{
    height: 900px;
}

.natural-image{
    max-width: 100%;
    max-height: 100%;
    width: fit-content;
    height: fit-content;
    object-fit: fill;
}

.details-comments-section{
    width: 60%;
}

.comment-time{
    font-size: 13px;
    font-weight: normal;
    color: #788195;
}

.comments-section ::-webkit-scrollbar {
    width: 3px;
}

.comments-section ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

.comments-section ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background:#bbbdc3;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}

.comments-section ::-webkit-scrollbar-thumb:window-inactive {
    background: #bbbdc3;
}

.consultation-notes{
    white-space: pre-wrap;
    color: #788195;
}

.consultation-notes-labels{
    font-size: 15px;
    font-weight: 500;
}

.horizontal-line{
   color: #ced4da;
   margin-top: 25px;
   margin-bottom: 25px;
}



.compressed-photo{
    width: 45%;
    height: 100%;
    max-height: 400px;
    object-fit: contain;
}

.field-height{
    min-height: 45px;
}

.filter-modal{
    position: fixed;
    top: 0;
    right: 0;
    min-height: 100vh;
}

.filter-modal-card{
    min-height: 100vh;
    min-width: 330px;
}

.splash-line{
    min-width: 331px;
    height: 2px;
    margin: 0 0 22px;
    border: solid 1px #eff2f7;
}

.modal-custom-content{
    border-radius: 0;
}

.apply-button{
    width: 263px;
    height: 36px;
    margin: 40px 34px 112px;
    padding: 0 38px 0 37px;
    border-radius: 30px;
}

.checkbox-label{
    font-size: 13px;
    font-weight: normal;
    color: #495057;
}

.group-label{
    font-size: 14px;
    font-weight: normal;
    color: #495057;
}

.filters-count{
    position: absolute;
    top: 0;
    right: 20px;
    width: 20px;
    height: 20px;
    margin: 8px 0 8px 72px;
    padding: 1px 6px 0;
    border-radius: 50%;
    background-color: rgba($color: #ffffff, $alpha: 0.3);
}

.attached-photo-history-tab{
    height: 215px;
    width: 100%;
    object-fit: contain;
}

.disable-word-wrap{
    word-wrap: normal;
    white-space: nowrap;
}

.fullname-table-cell{
    max-width: 150px;
    font-weight: 500;
}

.email-table-cell{
    width: fit-content;
    max-width: 150px;
}

@media (max-width: 1570px) {

    .date-table-cell{
        max-width: 110px;
        width: 110px;
    }

    .fullname-table-cell{
        max-width: 100px;
        font-weight: 500;
    }

    .email-table-cell{
        width: fit-content;
        max-width: 100px;
    }
}

.feedbacks-block {
    padding-bottom: 25px !important;

    .feedback {
        margin-top: 10px;

        .feedback-question {
            font-size: 13px;
            font-weight: 600;
            margin-right: 15px;
        }

        .feedback-question-rating {
            flex-basis: 140px;
        }

        .feedback-text {
            font-size: 13px;
            color: #788195;
            margin-bottom: 0;
        }
    }

    .details-caption {
        margin-bottom: 15px;
        font-weight: 500;
    }

    .details-caption:nth-of-type(2) {
        margin-top: 20px;
        margin-bottom: 5px;
    }

    .details-caption:nth-of-type(3) {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    hr {
        margin-top: 30px;
    }
}

.attached-photos-and-files-content {
    max-height: 490px;
    overflow-y: auto;
}

.log-page-nav-button {
    transition: 250ms;
    margin-left: 0;

    &:hover {
        margin-left: -3px;
    }

    .text {
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        color: $gunmetal;
    }

}

.child {
    &-row {
        background-color: $nested-row-color;
    }
    &-cell {
        padding-top: 5px !important;
        padding-bottom: 5px !important;
        vertical-align: middle !important;
        border-top: 2px solid white !important;

        &.cell-spacing {
            padding-left: 32px !important;
        }

    }
}

.add-patient-documents-modal {
    &__document-select-label {
        font-size: 13px;
    }

    &__document-select {
        width: 100%;
        max-width: 395px;
    }

    &__dropzone-section {
        height: 395px;
        max-width: 569px;
        max-height: 395px;
        width: 45%;
        margin-top: 2rem;
        position: relative;

        .error-text {
            position: absolute;
            bottom: -20px;
        }
    }


    &__dropzone {
        width: 100%;
        height: 100%;
    }

    &__buttons {
        max-width: 403px;
        width: 100%;
        gap: 29px;
    }

    .dropzone-file{
        margin-top: 0;
    }

  .dropzone-file .upload-container__text {
      font-size: 14px;
      line-height: 18px;
  }

    .search-options {
        max-width: unset;
    }

}
