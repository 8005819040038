$datepicker__background-color: #f0f0f0 !default;
$datepicker__border-color: #aeaeae !default;
$datepicker__highlighted-color: #3dcc4a !default;
$datepicker__muted-color: #ccc !default;
$datepicker__selected-color: #216ba5 !default;
$datepicker__text-color: #000 !default;
$datepicker__header-color: #000 !default;
$datepicker__navigation-disabled-color: lighten($datepicker__muted-color, 10%)
  !default;

$datepicker__border-radius: 50% !important;
$datepicker__day-margin: 0.166rem !default;
$datepicker__font-size: 0.8rem !default;
$datepicker__font-family: "Helvetica Neue", Helvetica, Arial,
  sans-serif !default;
$datepicker__item-size: 1.7rem !default;
$datepicker__margin: 0.4rem !default;
$datepicker__navigation-size: 0.45rem !default;
$datepicker__triangle-size: 8px !default;

.custom-calendar .react-datepicker {
    width: 365px;
    min-height: 330px;
    font-family: Poppins;
    font-size: 0.8rem;
    background-color: #fff;
    color: #000;
    border-radius: 0.3rem;
    display: inline-block;
    position: relative;
    border: 0;
}

.custom-calendar .react-datepicker__header{
    display: flex;
    flex-direction: column;
    background-color: white;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    padding-top: 8px;
    position: relative;
    border-bottom: 0;
}

.custom-calendar .react-datepicker__month-container{
    width: 100%;
    float: left;
}

.custom-calendar .react-datepicker__day-names{
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 20px;
    margin-right: 20px;
}

.custom-calendar .react-datepicker__week{
    background-color: white;
    display: flex;
    margin-left: 15px;
    margin-top: -5px;
}

.custom-calendar .react-datepicker__day{
    display: inline-block;
    color: #495057;
    width: 40px;
    height: 42px;
    background-color: white;
    text-align: center;
    padding-top: 10px;
    margin-right: 5px;
    margin-left: 2px;
    font-size: 14px;
    font-family: Poppins;
    outline: none;
}

.custom-calendar .react-datepicker__day-name{
    color: #788195;
    display: inline-block;
    width: 45px!important;
    height: 40px!important;
    background-color: #f8f9fa;
    text-align: center;
    padding-top: 10px;
    font-size: 14px;
    font-family: Poppins;
    margin: 0;
    line-height: unset;
}

.custom-calendar .react-datepicker__month--selected{
    border-radius: 50%;
    background-color: #216ba5;
    color: #fff;
}

.custom-calendar .react-datepicker__day:hover{
    border-radius: 50%;
    background-color:white;
}

.custom-calendar .react-datepicker__day--selected{
    background-color: #005eb8;
    border-radius: 50%;
    margin-top: 9px;
    padding-top: 3px;
    max-width: 30px;
    max-height: 30px;
    margin-right: 10px;
    margin-left: 7px;
    text-align: center;
    font-size: 14px;
    color: #fff;
}

.custom-calendar .react-datepicker__day--selected:hover{
    background-color: #005eb8;
    border-radius: 50%;
}

.custom-calendar .font-color-of-exclude-day{
    color: #bbbdc3;
}

.chevron-size{
    font-size: 20px;
}

.chevron-right-button{
    width: 40px;
    max-height: 30px;
    cursor: pointer;
    border: solid 1px lightgray;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
}

.chevron-left-button{
    width: 40px;
    max-height: 30px;
    cursor: pointer;
    border: solid 1px lightgray;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
}

.custom-datepicker-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: white;
    font-size: 15px;
}

.custom-calendar .date-label{
    color: #495057;
}

.custom-calendar .react-datepicker__day,
.custom-calendar .react-datepicker__month-text,
.custom-calendar .react-datepicker__quarter-text,
.custom-calendar .react-datepicker__year-text {
  cursor: pointer;

  &--today {
    font-weight: bold;
  }

  &--highlighted {
    border-radius: $datepicker__border-radius;
    background-color: $datepicker__highlighted-color;
    color: #fff;
  }
 
  &--range-start,
  &--range-end {
    background-color: #005eb8!important;
    border-radius: 50%;
    margin-top: 9px;
    padding-top: 3px;
    max-width: 30px;
    max-height: 30px;
    margin-right: 10px;
    margin-left: 7px;
    text-align: center;
    font-size: 14px;
    color: #fff!important;
  }

  &--in-selecting-range,
  &--in-range {
    background-color: #f2f7fe;
    min-width: 30px;
    text-align: center;
    font-size: 14px;
    color: #000;
  }

  &--in-selecting-range:not(&--in-range) {
    background-color: rgba( #f2f7fe, 0.5);
  }

  &--in-range:not(&--in-selecting-range) {
    .react-datepicker__month--selecting-range & {
      background-color: $datepicker__background-color;
      color: $datepicker__text-color;
    }
  }

  &--selecting-range-start,
  &--selecting-range-end{
    background-color: #005eb8!important;
    border-radius: 50%;
    margin-top: 9px;
    padding-top: 3px;
    max-width: 30px;
    max-height: 30px;
    margin-right: 10px;
    margin-left: 7px;
    text-align: center;
    font-size: 14px;
    color: #fff!important;
  }
  &--disabled {
    cursor: default;
    color: $datepicker__muted-color;
  }
}


.custom-calendar-input-range{
  position: absolute;
  z-index: 1000;
}

.custom-calendar-output-field{
  width: 185px;
  cursor: pointer;
}

.error-text{
  font-weight: 500;
  font-size: 10px;
  color: #d70000;
}

.custom-calendar-output-field[disabled]{
  background-color: #f2f2f2;
}
