.info-page-header{
    color: #495057;
    font-size: 22px;
    font-weight: 600;
}

.info-page-subheader{
    color: #495057;
    font-size: 15px;
    font-weight: 600;
}

.info-page-text{
    font-size: 13px;
    font-weight: normal;
    color: #788195;
}

.info-page-card{
    border-radius: 8px;
    padding: 48px 56px 106px 55px;
}

.info-page{
    margin: 66px 184px 65px 185px;
}

@media only screen and (max-width: 1024px){
    .info-page{
        margin: 0 0 0 0;
    }

    .info-page-card{
        padding: 32px 32px 32px 32px;
    }
}