.activate-code-page {
  //min-height: 800px;
  //height: 800px;
}

.activate-code-table-wrap {

  .action-buttons {
    max-width: 92px;
    width: 92px;
  }

  .downloadLink {
    color: #343A40;
  }
}

.activate-code-table-wrap,
.patient-history-log-table-wrap{
  min-height: 605px;
}

.action-table-cell {
  max-width: 80px;
  width: 80px;
}

.header-button {
  @media(min-width: 660px) {
    max-width: 222px;
  }
}

.no-coupons {
  height: 700px;
  &-label {
    color: $gray-700;
  }

  &-img {
    width: auto;
    max-width: 200px;
    max-height: 190px;
    height: auto;
  }
}

.coupon-form {
  &-radio-input {
    max-width: 140px;
  }

  &-input {
    &:disabled {
      background-color: #e9ecef;
      opacity: 1;
    }
  }

  &-label-disabled {
    color: $gray-600;
  }

  &-radio-wrapper {
    min-width: 109px;
  }

  &-radio-label {
    max-width: 0;
  }

  &-wrapper {
    display: grid;
    grid-gap: 24px;

    &-buttons {
      margin-top: 30px;
    }
  }

  &-file-icon {
    color:#005EB8;
  }

}

.coupon-table {
  height: 764px;
}

.activate-code__invalid-emails-modal {
  ::-webkit-scrollbar {
    width: 3px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #CED4DA;
    border-radius: 10px;
  }
}

.activate-code__invalid-emails-list {
  list-style-type: none;
  height: 100px;
  overflow: auto;
  padding: 0;
  margin-top: 18px;
  display: flex;
  flex-direction: column;
  gap: 18px;
  font-size: 13px;
  color: #F46A6A;
}
