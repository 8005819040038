
.device-settings-popup {

  .search-options {
    max-width: 100%;
  }

}

.modal-actions {
  display: grid;
  grid-gap: 30px;

  .input-heigth {
    max-height: 37px;
    color: #495057;
    font-size: 13px;
    font-weight: normal;
  }
}
