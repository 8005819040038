.appointments {
  &__header-actions {
    align-items: center;

    @media (max-width: 600px) {
      flex-direction: column;
      gap: 12px;
      align-items: flex-start;
    }
  }

  &__sessions-section {
    overflow: auto;
    width: 100%;
  }

  &__sessions-heading {
    background-color: #FFFFFF;
    font-weight: 600;
    border-radius: 4px;
  }

  &__group-wrapper {
    width: 100%;
    overflow: auto;
    margin-top: -7px;
    overflow-x: scroll;
    transform: rotateX(180deg);
  }

  &__group-content {
    display: flex;
    flex-direction: column;
    gap: 18px;
    transform: rotateX(180deg);
  }

  &__group__row {
    display: flex;
    flex-direction: row;
    gap: 18px;
  }

}

.appointment-price-section {
  background-color: #FFFFFF;
  width: 354px;
  padding: 12px 16px;
  border-radius: 3px;
  box-shadow: 0 10px 20px 0 rgba(18, 38, 63, 0.03);
  gap: 12px;

  @media (max-width: 400px) {
    flex-direction: column;
    gap: 12px;
    align-items: flex-start;
  }

  &__label {
    font-weight: 500;
  }

  &__value {
    color: #005EB8;
    font-size: 16px;
    font-weight: 600;
    margin-left: 2px;
  }

  &__change-price-button {
    padding: 1px 12px;
    height: 36px;

    img {
      width: 16px;
      height: 16px;

      fill: #005EB8;
    }
  }

}
