// 
// authentication.scss
//

.home-btn {
    position: absolute;
    top: 15px;
    right: 25px;
}

.card-auth .form-caption-auth{
    max-width: 100%;
    max-height: 135px;
    background-color:#a7d2f8;
}

.card-auth{
    border-radius: 8px !important;
}

.card-auth .caption-profile-img{
    margin-top: 22px;
}

.card-auth .eye-size{
    font-size: 24px;
    color: lightgray;
}

.card-auth .eye-position{
    position: absolute;

    right: 25px;
    top: 27px;
}

.card-auth .lock-size{
    font-size: 20px;
    position: absolute;
    top: 14px;
    left: 125px;
}

.card-auth .validation-error{
    position: absolute;
    top: 65px;
    color: #f46a6a;
    font-size: 10px;
}

.card-auth .cursor-pointer{
    cursor: pointer;
}

.expiredLinkLogo{
    width: 125px;
    height: 114px;
    padding: 0 0 6px;
    object-fit: contain;
}

.expiredLinkLabel{
    width: 186px;
    height: 23px;
    font-size: 16.3px;
    font-weight: normal;
}

.expiredLinkCard{
    margin-top: 250px;
    width: 451px;
    height: 344px;
    padding: 54px 28px 24px;
    border-radius: 8px;
    box-shadow: 0 10px 20px 0 rgba(18, 38, 63, 0.03);
}

.account-pages .password-input-padding{
    padding-right: 55px;
}

.account-pages .restore-password-label{
    padding-top: 20px;
    padding-bottom: 20px;
    font-weight: normal;
}

.modal-header .close-logout-button{
    opacity: 1;
}