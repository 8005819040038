.custom-header{
    background-color: #ffffff;
    width: 250px;
    padding: 0 1.5rem;
    text-align: center;
}

.calls-page-content{
    margin-top: 95px;
    margin-left: 25px;
    margin-right: 25px;
    margin-bottom: 50px;

      @media screen and (-webkit-min-device-pixel-ratio:0) {
        input[type='range'] {
          overflow: hidden;
          width: 80px;
          height: 6px;
          -webkit-appearance: none;
        }
        
        input[type='range']:hover:-webkit-slider-runnable-track {
          height: 10px;
          -webkit-appearance: none;
          color:#ccc;
        }

        input[type=range]::-webkit-slider-runnable-track {
            background: rgba($color: #ffffff, $alpha: 0.2);
        }
        input[type='range']::-webkit-slider-thumb {
          width: 3px;
          -webkit-appearance: none;
          height: 3px;
          cursor: pointer;
          box-shadow: -80px 0 0 80px #ffffff;
        }
    }

    &__call{
        max-height: 650px;
    }
}

.label-parents-info{
    font-size: 16px;
    font-weight: 600;
}

.patient-extra-section{
    flex-basis: 80%;
}

.drop-down-icon{
    position: absolute;
    top: 40px;
    right: 10px;
}

.call-button-white{
    width: 62px;
    height: 62px;
    border-radius: 50%;
    box-shadow: 0 10px 20px 0 rgba(18, 38, 63, 0.03);
    background-color: #ffffff;
    padding: 18px;
}

.call-button-red{
    width: 62px;
    height: 62px;
    margin-left: 8px;
    margin-right: 8px;
    border-radius: 50%;
    box-shadow: 0 10px 20px 0 rgba(18, 38, 63, 0.03);
    background-color:#d70000;
    padding: 13px;
}

.call-button-transparent{
    width: 62px;
    height: 62px;
    margin-left: 8px;
    margin-right: 8px;
    border-radius: 50%;
    box-shadow: 0 10px 20px 0 rgba(18, 38, 63, 0.03);
    background-color: rgba($color: #ffffff, $alpha: 0.2);
    padding: 19px;

}

.volume-slider{
    position: absolute;
    bottom: 140px;
    left: 40px;
    width: 85px;
    transform: rotate(270deg);
    z-index: 999999;
    background-color: transparent!important;
}

.call-buttons-section{
    position: absolute;
    bottom: 35px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.call-small-picture{
    position: absolute;
    top: 20px;
    right: 20px;
    width: 200px;
    height: 150px;
}

.call-big-picture{
    object-fit: cover;
    min-height: 650px;

    &__remote-participant-container{
        max-height: 650px;
    }
}


.call-timer-section{
    position: absolute;
    top: 24px;
    left: 24px;
    width: 95px;
    height: 35px;
    padding: 3px 27px 2px 10px;
    border-radius: 4px;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
}

.call-red-oval{
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 10px 15px 6px 0;
    background-color: #d70000;
}

.call-timer{
    font-size: 20px;
    font-weight: normal;
    color: #495057;
}

.feedback-field{
    font-size: 13px;
    font-weight: normal;
    color: #495057;
}

.see-details{
    font-size: 13px;
    font-weight: normal;
    color: #495057;
}

.call-consultation-notes{
    padding: 20px 20px 30px 20px;
    height: 650px;
    max-height: 650px;

    &__inputs-section{
        max-height: 500px;
        overflow-y: auto;
    }
}

.consultation-label{
    font-size: 15px;
    font-weight: 600;
    color: #495057;
    margin-bottom: 24px;
}

.call-form-button{
    margin-top: 40px;
    border-radius: 4px;
    font-size: 13px;
    font-weight: 500;
}

.simple-textarea{
    border-radius: 4px;
    max-height: 36px;
}

.big-textarea{
    border-radius: 4px;
    min-height: 116px;
}

.avarage-textarea{
    border-radius: 4px;
    min-height: 90px;
}

.consultation-col{
    padding-left: 2%;
}

.video-placeholder{
    width: 100%;
    height: 95%;
    background-color: #495057;
    min-height: 700px;
}

.off-camera-placeholder{
    width: 200px;
    height: 150px;
    background-color: #343a40;
}

.custon-notification-two-minutes{
    position: absolute;
    top: 80px;
    width: 60%;
    border-radius: 4px;
    background-color: #005eb8;
    color: #ffffff;
    border: none;
    max-height: fit-content;
    padding: 5px;
    padding-right: 30px;

    .close{
        display: none;
    }

    &__close{
        position: absolute;
        top: 7px;
        right: 7px;
        cursor: pointer;
    }
}

.custon-notification-extra-time{
    position: absolute;
    top: 140px;
    width: 60%;
    border-radius: 4px;
    background-color: #005eb8;
    color: #ffffff;
    border: none;
    max-height: fit-content;
    padding: 5px;
    padding-right: 30px;

    .close{
        display: none;
    }

    &__close{
        position: absolute;
        top: 7px;
        right: 7px;
        cursor: pointer;
    }
}



.custom-notification-left{
    font-size: 12px;
    font-weight: normal;
}

.call-ended-label{
    font-size: 22px;
    font-weight: 600;
    color: #495057;
}

.leave-consultation-button {
    background-color: #fcc000;
    color: #ffffff;
    font-size: 13px;
    font-weight: 500;

    .submitting {
        width: 129px;
    }
}

.back-color-call{
    background-color: #495057;
    color: #ffffff;
}

.call-error-placeholder{
    height: 650px;
    max-height: 650px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__image{
        margin-top: 160px;
        width: 333px;
        height: 233px;
        object-fit: contain;
    }

    &__bold-label{
        font-size: 15px;
        font-weight: 600;
        letter-spacing: normal;
        text-align: center;
        color: $gunmetal;
        padding-left: 175px;
        padding-right: 175px;
        margin-top: 33px;
    }

    &__label{
        font-size: 13px;
        font-weight: normal;
        text-align: center;
        color: $steel;
        margin-top: 16px;
        padding-left: 45px;
        padding-right: 45px;
    }
}

.remote-participant{
    width: 100%;
    max-height: 650px;
    min-height: 650px;
    object-fit: contain;
    border-radius: 0.25rem;
}

.call-notifications{

    position: absolute;
    top: 75px;
    width: 60%;

    &__primary{
        background-color: #005eb8;
    }

    &__success{
        background-color: #34c38f;
    }

    &__danger{
        background-color: #d70000;
    }
    
    .custom-notification{
        border-radius: 4px;
        min-height: 33px;
        color: #ffffff;
        border: none;
        max-height: fit-content;
        padding: 5px;
        padding-right: 30px;
        display: flex;
        flex-direction: row;
        align-items: center;
    
        .close{
            display: none;
        }
    
        &__close{
            position: absolute;
            top: 7px;
            right: 7px;
            cursor: pointer;
        }
    }
}

.feedback-modal-content{
    .modal-content{
        background-color: transparent!important;
        border: none;
        .card{
            background-color: transparent!important;
            border: none;
        }
    }
}
