
.autocomplete-wrapper {
  position: relative;
}

.autocomplete-dropdown-container {
  max-height: 225px;
  overflow-y: auto;
  padding-bottom: 4px;
  padding-top: 4px;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
  width: 100%;
  top: 96%;
  background-color: hsl(0,0%,100%);
  border-radius: 4px;
  box-shadow: 0 0 0 1px hsla(0,0%,0%,0.1), 0 4px 11px hsla(0,0%,0%,0.1);
  margin-bottom: 8px;
  margin-top: 8px;
  position: absolute;
  z-index: 1;
}

.autocomplete-dropdown__loading,
.suggestion__item--active,
.suggestion__item {
  font-size: 13px;
  font-weight: normal;
  color: #495057;
  padding: 8px 12px;

}


.suggestion__item {
  cursor: pointer;
}

.suggestion__item--active {
  background-color: #2684FF;
  color: hsl(0,0%,100%);
}

.suggestion__text {
  background-color: transparent;
  color: inherit;
  cursor: default;
  display: block;
  font-size: inherit;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  box-sizing: border-box;
}
