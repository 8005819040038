.full-screen-photo{
    width: fit-content;
    height: fit-content;
}

.image-overflow{
    overflow-x: auto;
    overflow-y: auto;
    object-fit: fill;
    visibility: visible;
}

.image-view{
    max-height: 100vh;
}